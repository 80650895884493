import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Github, Globe, MessageCircle, RocketIcon, Code } from 'lucide-react';

const GridBackground = () => {
    const canvasRef = useRef(null);
    const pointsRef = useRef([]);
    const frameRef = useRef();

    // Мемоизируем константы
    const CONFIG = useMemo(() => ({
        gridSize: 30,
        maxDistance: 150,
        force: 0.5,
        speed: 0.3,
        connectionRadius: 45, // gridSize * 1.5
    }), []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { alpha: false }); // Оптимизация для непрозрачного фона
        let mouseX = 0;
        let mouseY = 0;
        let animationFrameId;

        function setCanvasSize() {
            // Используем devicePixelRatio для четкости на ретина-дисплеях
            const dpr = window.devicePixelRatio || 1;
            canvas.width = window.innerWidth * dpr;
            canvas.height = window.innerHeight * dpr;
            canvas.style.width = `${window.innerWidth}px`;
            canvas.style.height = `${window.innerHeight}px`;
            ctx.scale(dpr, dpr);
        }

        function initPoints() {
            const points = [];
            // Уменьшаем количество точек для производительности
            for (let x = 0; x < canvas.width; x += CONFIG.gridSize * 1.5) {
                for (let y = 0; y < canvas.height; y += CONFIG.gridSize * 1.5) {
                    points.push({
                        x: x,
                        y: y,
                        baseX: x,
                        baseY: y,
                    });
                }
            }
            pointsRef.current = points;
        }

        // Используем throttle для обработки движения мыши
        let lastMove = 0;
        const handleMouseMove = (e) => {
            const now = Date.now();
            if (now - lastMove > 16) { // ~60fps
                mouseX = e.clientX;
                mouseY = e.clientY;
                lastMove = now;
            }
        };

        function draw() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.beginPath();
            ctx.strokeStyle = 'rgba(0,72,255,0.1)';

            const points = pointsRef.current;
            const len = points.length;

            // Используем быструю проверку расстояния
            const fastDistance = (dx, dy) => dx * dx + dy * dy;
            const maxDistanceSquared = CONFIG.maxDistance * CONFIG.maxDistance;
            const connectionRadiusSquared = CONFIG.connectionRadius * CONFIG.connectionRadius;

            for (let i = 0; i < len; i++) {
                const point = points[i];

                const dx = mouseX - point.baseX;
                const dy = mouseY - point.baseY;
                const distanceSquared = fastDistance(dx, dy);

                if (distanceSquared < maxDistanceSquared) {
                    const force = (CONFIG.maxDistance - Math.sqrt(distanceSquared)) / CONFIG.maxDistance;
                    point.x = point.baseX - (dx * force * CONFIG.force);
                    point.y = point.baseY - (dy * force * CONFIG.force);
                } else {
                    point.x += (point.baseX - point.x) * CONFIG.speed;
                    point.y += (point.baseY - point.y) * CONFIG.speed;
                }

                // Оптимизируем проверку соединений
                for (let j = i + 1; j < len; j++) {
                    const pointB = points[j];
                    const dx = point.x - pointB.x;
                    const dy = point.y - pointB.y;

                    if (fastDistance(dx, dy) < connectionRadiusSquared) {
                        ctx.moveTo(point.x, point.y);
                        ctx.lineTo(pointB.x, pointB.y);
                    }
                }
            }
            ctx.stroke();

            // Рисуем точки одним проходом
            ctx.fillStyle = 'rgba(0,34,255,0.8)';
            points.forEach(point => {
                ctx.beginPath();
                ctx.arc(point.x, point.y, 1, 0, Math.PI * 2);
                ctx.fill();
            });

            frameRef.current = requestAnimationFrame(draw);
        }

        setCanvasSize();
        initPoints();
        window.addEventListener('resize', setCanvasSize);
        window.addEventListener('resize', initPoints);
        window.addEventListener('mousemove', handleMouseMove);
        frameRef.current = requestAnimationFrame(draw);

        return () => {
            cancelAnimationFrame(frameRef.current);
            window.removeEventListener('resize', setCanvasSize);
            window.removeEventListener('resize', initPoints);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [CONFIG]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 0,
                backgroundColor: '#0a0a0a',
            }}
        />
    );
};

function App() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = {
        container: {
            minHeight: '100vh',
            position: 'relative',
            padding: '2rem',
        },
        content: {
            position: 'relative',
            zIndex: 1,
            maxWidth: '1400px',
            margin: '0 auto',
        },
        mainLayout: {
            display: 'grid',
            gridTemplateColumns: width < 768 ? '1fr' : '300px 1fr',
            gap: '2rem',
        },
        profileCard: {
            backgroundColor: 'rgba(18, 18, 18, 0.85)',
            backdropFilter: 'blur(10px)',
            borderRadius: '12px',
            padding: '2rem',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            height: 'fit-content',
        },
        avatar: {
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            margin: '0 auto 1.5rem',
            display: 'block',
            border: '4px solid rgba(0, 255, 100, 0.2)',
        },
        name: {
            fontSize: '1.75rem',
            fontWeight: '600',
            color: '#00ff80',
            textAlign: 'center',
            margin: '0',
        },
        username: {
            color: '#888',
            textAlign: 'center',
            margin: '0.5rem 0 1.5rem',
        },
        timeline: {
            borderLeft: '2px solid rgba(0, 255, 100, 0.2)',
            padding: '0 0 0 1.5rem',
            margin: '2rem 0',
        },
        timelineItem: {
            marginBottom: '1.5rem',
        },
        contentCard: {
            backgroundColor: 'rgba(18, 18, 18, 0.85)',
            backdropFilter: 'blur(10px)',
            borderRadius: '12px',
            padding: '2rem',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            marginBottom: '2rem',
        },
        contentGrid: {
            display: 'grid',
            gridTemplateColumns: width < 1200 ? '1fr' : '1fr 1fr',
            gap: '2rem',
        },
        heading: {
            color: '#00ff80',
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
            margin: '0 0 1.5rem',
            fontSize: '1.5rem',
        },
        techStack: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.75rem',
        },
        techChip: {
            backgroundColor: 'rgba(42, 42, 42, 0.6)',
            color: '#00ff80',
            padding: '0.5rem 1rem',
            borderRadius: '16px',
            border: '1px solid rgba(0, 255, 100, 0.1)',
            fontSize: '0.875rem',
        },
        projectCard: {
            backgroundColor: 'rgba(42, 42, 42, 0.6)',
            borderRadius: '8px',
            padding: '1.5rem',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            transition: 'all 0.3s ease',
        },
        projectTitle: {
            color: '#00ff80',
            margin: '0 0 1rem',
            fontSize: '1.25rem',
        },
        description: {
            color: '#888',
            margin: 0,
            lineHeight: 1.6,
        },
    };

    return (
        <div style={styles.container}>
            <GridBackground />
            <div style={styles.content}>
                <div style={styles.mainLayout}>
                    {/* Profile Section */}
                    <aside style={styles.profileCard}>
                        <img
                            src="https://avatars.githubusercontent.com/u/51033415?v=4"
                            alt="Profile"
                            style={styles.avatar}
                        />
                        <h1 style={styles.name}>Вадим Корниенков</h1>
                        <p style={styles.username}>@metnerium</p>

                        <div style={styles.timeline}>
                            <div style={styles.timelineItem}>
                                <h3 style={{margin: '0 0 0.5rem', color: '#fff'}}>МГТУ им. Баумана</h3>
                                <p style={{margin: '0 0 0.25rem', color: '#eee'}}>Факультет биомедицинской техники</p>
                                <p style={{margin: 0, color: '#888', fontSize: '0.875rem'}}>Прикладная информатика</p>
                            </div>
                            <div style={styles.timelineItem}>
                                <h3 style={{margin: '0 0 0.5rem', color: '#fff'}}>Группа Астра</h3>
                                <p style={{margin: 0, color: '#eee'}}>Инженер-программист</p>
                            </div>
                        </div>
                        {/* Add this inside the aside element, after the timeline div */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.75rem',
                            marginTop: '1.5rem'
                        }}>
                            <a
                                href="http://metnerium.tech"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.75rem',
                                    padding: '0.75rem 1rem',
                                    backgroundColor: 'rgba(42, 42, 42, 0.6)',
                                    border: '1px solid rgba(0, 255, 100, 0.1)',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    textDecoration: 'none',
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                <Globe size={20} />
                                <span>Website</span>
                            </a>

                            <a
                                href="https://t.me/metnerium"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.75rem',
                                    padding: '0.75rem 1rem',
                                    backgroundColor: 'rgba(42, 42, 42, 0.6)',
                                    border: '1px solid rgba(0, 255, 100, 0.1)',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    textDecoration: 'none',
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                <MessageCircle size={20} />
                                <span>Telegram</span>
                            </a>

                            <a
                                href="https://github.com/metnerium"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.75rem',
                                    padding: '0.75rem 1rem',
                                    backgroundColor: 'rgba(42, 42, 42, 0.6)',
                                    border: '1px solid rgba(0, 255, 100, 0.1)',
                                    borderRadius: '8px',
                                    color: '#fff',
                                    textDecoration: 'none',
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                <Github size={20} />
                                <span>GitHub</span>
                            </a>
                        </div>
                    </aside>

                    {/* Main Content */}
                    <main>
                        <div style={styles.contentCard}>
                            <h2 style={{...styles.heading, fontSize: '2rem'}}>Привет, я Вадим!</h2>
                            <p style={styles.description}>
                                Full-stack разработчик и технический предприниматель, специализирующийся на создании
                                высоконагруженных систем и блокчейн-решений.
                            </p>
                        </div>

                        <div style={styles.contentCard}>
                            <h2 style={styles.heading}>
                                <Code size={24}/>
                                Технологический стек
                            </h2>
                            <div style={styles.techStack}>
                                {['C++', 'Python', 'Vue.js', 'React', 'Node.js', 'Docker', 'Kubernetes', 'Linux', 'Bash']
                                    .map((tech) => (
                                        <span key={tech} style={styles.techChip}>{tech}</span>
                                    ))}
                            </div>
                        </div>

                        <div style={styles.contentCard}>
                            <h2 style={styles.heading}>
                                <Github size={24} />
                                GitHub статистика
                            </h2>
                            <div style={styles.contentGrid}>
                                <div style={styles.projectCard}>
                                    <img
                                        src="https://camo.githubusercontent.com/120872d9462cd394f634f40ba84ad5634ba147c7c4d537fa4cdcce0d2bd1eff1/687474703a2f2f6769746875622d726561646d652d73747265616b2d73746174732e6865726f6b756170702e636f6d3f757365723d6d65746e657269756d267468656d653d6461726b266261636b67726f756e643d303030303030"
                                        alt="GitHub Streak Stats"
                                        style={{width: '100%', borderRadius: '4px'}}
                                    />
                                </div>
                                <div style={styles.projectCard}>
                                    <img
                                        src="https://camo.githubusercontent.com/0304b3d9f11fad51ab1884945ea8fb522a4e6571d078bb6b189a3d2383c2c4a2/68747470733a2f2f6769746875622d726561646d652d73746174732e76657263656c2e6170702f6170692f746f702d6c616e67732f3f757365726e616d653d6d65746e657269756d266c61796f75743d636f6d70616374267468656d653d766973696f6e2d667269656e646c792d6461726b"
                                        alt="GitHub Language Stats"
                                        style={{width: '100%', borderRadius: '4px'}}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={styles.contentCard}>
                            <h2 style={styles.heading}>
                                <RocketIcon size={24} />
                                Ключевые проекты
                            </h2>
                            <div style={styles.contentGrid}>
                                <div style={styles.projectCard}>
                                    <h3 style={styles.projectTitle}>ReadRoom</h3>
                                    <p style={styles.description}>
                                        Высоконагруженный сервис для обработки и анализа данных.
                                        Микросервисная архитектура, масштабируемость.
                                    </p>
                                </div>
                                <div style={styles.projectCard}>
                                    <h3 style={styles.projectTitle}>MedicalLink</h3>
                                    <p style={styles.description}>
                                        Децентрализованная система хранения медицинских данных на блокчейне.
                                        Безопасность и прозрачность.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default App;